html {
    background-color: #FFFFFF;
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
}
:root {
    --text-primary-color: #504356;
    --heаding-primary-color: #252F55;
    --bg-primary-color: #E3F3F9;
    --link-primary-color: #FF6359;
    --button-primary-color: #FF6359;
    --paragrapgh-color: #CED1EE;
    --li-color: #384575;
    --li-circle-color: #6EACF5;
    --bg-box: #F5F4F7;
}
.container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1149px;
}

body {
    font-size: 14px;
    line-height: 26px;
    font-family: 'Raleway', sans-serif;
    color: #FFFFFF;
    background: #FFFFFF;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    scroll-behavior: smooth;
    min-width: 320px;
    margin: 0px;
}

h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 58px;
    line-height: 136.4%;
    color: #252F55;
    margin: 20px 0;
}

h2 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 136.4%;
    color: var(--heаding-primary-color);
    margin: 30px 0;
}

h3 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 136.4%;
    color: var(--heаding-primary-color);
    margin: 20px 0;

    }
p {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        text-align: left;
        color: var(--text-primary-color);
        margin: 20px 0;
}
    
    .slide-down {
        height: 0;
        overflow: hidden;
        transition: height 0.2s linear;
    }
    
    .header-description {
        line-height: 30px;
        padding-bottom: 30px;
    
    }
    
    .read-more-wrapper {
        margin-top: 12px;
    }
    
    .read-more {
        color: #2B25D9;
        text-decoration: underline;
    }

    .read-more:hover {
        cursor: pointer;
    }

    .m-slide-down-measure p {
        padding-top: 20px;
        margin: 0 !important;
    }
    
    .t-top-tabs ul {
        list-style: none;
        margin-bottom: 60px;
        text-align: center;
    }
    
    .t-top-tabs ul li {
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 4px 19px rgba(46,32,86,.1);
        display: inline-block;
        margin: 0 20px;
        position: relative;
        transition: margin .2s linear;
        cursor: pointer;
    }
    
    .t-top-tabs ul li a {
        display: inline-block;
        width: 240px;
        height: 72px;
        line-height: 80px;
        text-decoration: none;
        text-align: center;
        margin-bottom: 3px;
        margin-left: 10px;
        margin-right: 10px;
    }
    
    .t-top-tabs ul li a {
        font-family: Poppins;
        font-size: 16px;
        font-weight: normal;
        color: #2E2056;
        border-bottom: 4px solid #6EACF5;
        transition: border 0.2s linear;
    }
    
    .t-top-tabs ul li.active a, 
    .t-top-tabs ul li:hover a {
        border-bottom: 4px solid var(--link-primary-color);
    }
    
    .t-top-tabs ul li.active a::after {
        border-top: 12px solid var(--link-primary-color);
        top: 93%;
        z-index: 2;
    }
    
    .t-top-tabs ul li.active::after {
        border-top: 12px solid #fff;
        top: 100%;
        z-index: 1;
    }
    
    .t-top-tabs ul li.active::after, 
    .t-top-tabs ul li.active a::after {
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        clear: both;
        content: '';
        height: 0;
        width: 0;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
    }
    
    .t-section .tab {
        height: 0;
        opacity: 0;
        overflow: hidden;
    
    }
    
    .t-section .tab.active {
        height: auto;
        opacity: 1;
        transition: opacity 0.3s linear;
    }
.text-center {
    text-align: center;
}

.text-white {
    color: #FFFFFF
}

.grid {
    display: grid;
    grid-gap: 20px;
    gap: 20px;
}

.col2 {
    grid-template-columns: 1fr 1fr;
}

.col2-1 {
    grid-template-columns: 2fr 1fr;
}

.col1-2 {
    grid-template-columns: 1fr 2fr;
}

a {
    text-decoration: none;
    color: var(--link-primary-color);
}
a.text-underline{
    text-decoration: underline;
}


/* Banner */
.banner{
    background: #FFFFFF;
    box-shadow: 0px 4px 105px rgba(34, 48, 74, 0.2);
    border-radius: 0px 0px 10px 10px;
    padding: 10px 20px 10px 10px;
    display: grid;
    gap: 30px;
    align-items: center; 
    grid-template-columns: 0.8fr 2fr 0.7fr;
}
.banner .logo-box{
    background: #EAF2F8;
    border-radius: 10px;
}
.banner .logo-box a{
    background: url(../image/domainer_logo.svg);
    background-repeat: no-repeat;
    background-position: center center;
    color: transparent;
    display: block;
    font-size: 0;
    padding: 20px 30px;
    height: 138px;
}
.banner .title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 15px;
    color: #090909;
}
.banner .info{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #7D7F82;
    position: relative;
    margin: 0 0 0 15px;
    background-color: transparent;
}
.banner .info:before{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 6px;
    height: 100%;
    margin-left: -15px;
    border-radius: 10px;
    background: #F27558;
}
.banner .domaner-btn{
    background: #F27558;
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
    padding: 23px 5px;
    display: block;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #000000;
}
.banner .domaner-btn:hover {
    background-color: rgb(249, 173, 141);
}
@media only screen and (max-width: 1090px) {
    .banner .title{
        font-size: 22px;
        margin-bottom: 10px;
    }
    .banner .info{
        font-size: 18px;
    }
    .banner .domaner-btn{
        font-size: 18px;
    }
    .banner .domaner-btn {
        font-size: 18px;
        line-height: 22px;
        display: table;
        margin: 0 auto;
        padding: 23px 30px;
    }
}
  @media only screen and (max-width: 940px) {
    .banner{
        grid-template-columns: 1fr;
        padding: 10px;
    }
    .banner .logo-box a{
        background: url(../image/domainer_logo_mob.svg);
        background-repeat: no-repeat;
        background-position: center center;
        height: 66px;
    }
    .banner .title{
        line-height: 24px;
        text-align: center;
    }
    .banner .info{
        font-size: 16px;
        line-height: 20px;
        text-align: center;
    }
    .banner .info:before{
        content: none;
    }
  }
  @media only screen and (max-width: 440px) {
    .banner .domaner-btn {
        display: block;
        padding: 23px 0px;
    }
  }

/* body */
.scroll-to-link > *{
    pointer-events: none;
 }
.site-header {
    width: 100%;
    background: linear-gradient(177.29deg, #E3F3F9 6.23%, rgba(227, 243, 249, 0) 97.14%);;
    padding: 0 0 30px;
    position: relative;
}

.site-logo {
    position: relative;
    z-index: 3;
    padding: 20px 0;
}

.site-logo .main-logo, .footer .main-logo{
    position: relative;
    font-family: Poppins;
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    color: #133F5C;
}

.intro-primary {
    width: 100%;
    background: var(--bg-primary-color);
    padding-bottom: 80px;
    margin-bottom: 70px;
    position: relative;
}

.blur-circle {
    position: absolute;
    width: 30%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: var(--bg-circle-color);
    filter: blur(150px);
}

.blur-circle-right {
    position: absolute;
    width: 20%;
    height: 25%;
    top: 0;
    left: auto;
    right: 0;
    bottom: 0;
    background: var(--bg-circle-color);
    filter: blur(150px);

}

.blur-circle-bottom {
    position: absolute;
    width: 26%;
    height: 14%;
    top: 50%;
    left: 30%;
    right: 0;
    bottom: 0;
    background: var(--bg-circle-color);
    filter: blur(150px);
}

.intro-primary .title {
    position: relative;
    z-index: 3;
}
.intro-entry {
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color:#504356;
    margin-bottom: 60px;
}
.main-image{
    background-image: url("../image/clothes.png"); 
    height: 500px; 
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.sub-title {
    padding: 0 100px;
}
.gradient-btn {
    background: #6bb8ff5c;
    box-shadow: 0px 35px 73px -11px rgba(84, 114, 131, 0.5);
    border-radius: 69px;
    height: 82px;
    padding: 3px;
    width: 354px;
}
.gradient-btn .inner {
    position: relative;
    height: 100%;
    width: 100%;
    border: none;
    border-radius: 69px;
    color: #fff;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: bold;
    text-align: center;
    transition: font-size 0.4s linear;
    background: linear-gradient(94.06deg, #6BB8FF 1.25%, #7489D3 71.25%);
}

.gradient-btn .inner:after {
    content: '';
    opacity: 0;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: inherit;
    transition: opacity .4s linear;
    background: linear-gradient(94.06deg, #6BB8FF 98.75%, #7489D3 28.75%);
}

.gradient-btn:hover .inner:after {
    opacity: 1; 
}

.gradient-btn a {
    color: white;
    text-decoration: none;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
}
.button {
    display: inline-block;
    padding: 15px 30px;
    color: #FFFFFF;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    text-decoration: none;
    border-radius: 69px;
    position: relative;
    background-color: #ccc;
    background-image: linear-gradient(94.06deg, #6CB5FD 1.25%, #7589D4 71.25%);
    -webkit-backface-visibility: hidden;
    z-index: 1;
    white-space: nowrap;
  }
  .button:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 69px;
    background-image: linear-gradient(94.06deg, var(--button-primary-color) 1.25%, var(--button-primary-color) 71.25%);
    transition: opacity 0.5s ease-out;
    z-index: 2;
    opacity: 0;
  }
  .button:hover:after {
    opacity: 1;
  }
  .button span {
    position: relative;
    z-index: 3;
  }
/* table */
.t-top-title {
    background-image: url(../image/arrows2.png), url(../image/arrows1.png);
    background-position: left top, right top;
    background-repeat: no-repeat, no-repeat;
    margin-top: 50px;
}
.t-top-tabs {
    display: table;
    margin: 0 auto;
}

.t-section {
    border-radius: 26px;
    padding: 15px;
    margin-top: 40px;
}
.tabs-wrapper{
    background: #1C2132;
    border-radius: 26px;
    padding: 15px;
}
.mytabs {
    display: none;
    height: 0;
    opacity: 0;
}

.mytabs >  div {
    overflow: hidden;
}

.mytabs.active {
    height: auto;
    opacity: 1;
    display: block;
    transition: opacity 0.3s linear;
}

.d-none {
    display: none;
}

table.home-table {
    width: 100%;
    border: 0;
    border-collapse: separate;
    border-spacing: 0 10px;
    margin-top: -10px;
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #2E2056;
}

.home-table thead tr {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #96A0AE;
    text-transform: uppercase;
}

.home-table tbody tr {
    padding: 5px 5px 5px 20px;
    border-radius: 28px;
    margin: 15px 0;
}

table.home-table td {
    border: solid 1px #f9fbff;
    border-style: solid none;
    padding: 10px;
    background-color: #F9FBFF;
    text-align: center;
    max-width: 250px;
    position: relative;
}

table.home-table td:not(:first-of-type, :last-of-type, :nth-of-type(5))::after {
    content: '';
    height: 80%;
    width: 1px;
    position: absolute;
    right: 0;
    top: 10px;
    background-color: #F1F4F9;
}

table.home-table td:first-child {
    border-left-style: solid;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

table.home-table td:last-child {
    border-right-style: solid;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
}
table.home-table li{
    margin-left: 20px;
}
.t-logo{
    background: #f1f4f9;
    border-radius: 25px;
    flex: 0 0 18%;
    padding: 20px 0;
    width: 100%;
}
.t-logo .logo {
    background-color: transparent;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    font-size: 0;
    height: 40px;
    margin: 0 auto;
    max-width: 150px;
    width: 100%;
    width: 150px;
}
.t-logo .logo.logo-unxd{
    background-image:  url("../image/unxd-logo.png");
}
.t-logo .logo.logo-digitalax{
    background-image:  url("../image/digitalax-logo.png");
}
.t-logo .logo.logo-dcentlife{
    background-image:  url("../image/dcent-logo.png");
}
.t-logo .logo.logo-rtfkt{
    background-image:  url("../image/rtfkt-logo.png");
}
.t-logo .logo.logo-dematerialized{
    background-image:  url("../image/thedematerialised-logo.png");
}
.t-logo .logo.logo-fabricant{
    background-image:  url("../image/thefabricant-logo.png");
}
.t-logo .logo.logo-pablovittar{
    background-image:  url("../image/pabllovittar-logo.png");
}
.t-logo .logo.logo-makingstrides{
    background-image:  url("../image/makingstrides-logo.png");
}
.t-logo .logo.logo-fewocious{
    background-image:  url("../image/fewociousxrtfkt-logo.png");
}
.t-logo .logo.logo-jesfashion{
    background-image:  url("../image/jesfashion-logo.png");
}
.t-logo .logo.logo-coinbase{
    background-image:  url("../image/fewociousxrtfkt-logo.png");
}
.t-logo .logo.logo-commonlight{
    background-image:  url("../image/commonlight-logo.png");
}
.t-logo .logo.logo-beyondsneakers{
    background-image:  url("../image/beyond-sneakers.png");
}
.t-logo .logo.logo-tomyoo{
    background-image:  url("../image/tomyoo-logo.png");
}
.t-logo .logo.logo-cryptosneakers{
    background-image:  url("../image/cryptosneakers-logo.png");
}
.t-logo .logo.logo-oxuniverse{
    background-image:  url("../image/operators/oxuniverse_logo.png");
}
.t-logo .logo.logo-splinterlands{
    background-image:  url("../image/operators/splinterlands_logo.png");
}
.t-logo .logo.logo-cropbytes{
    background-image:  url("../image/operators/cropbytes_logo.png");
}
.t-logo .logo.logo-forestknight{
    background-image:  url("../image/operators/forestknight_logo.png");
}
.t-logo .logo.logo-chainmonsters{
    background-image:  url("../image/operators/chainmonsters-logo.png");
}
.t-os-icon{
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: auto;
    height: 30px;
    margin: 0 auto;
    width: 50px;
    display: inline-block;
    vertical-align: middle;
    font-size: 0;
}

.t-rating {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    min-width: 80px;
}
.t-rating .icon{
    content: '';
    width: 20px;
    height: 20px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    float: left;
    margin: 0px 10px 0 0px;
    font-size: 0px;
    background-image: url(../image/star.png);
}
.t-rating img:first-child,
.t-os img:first-child {
    margin-right: 15px;
}

ul.list-highlights {
    text-align: left;
    list-style: none;
    font-weight: 500;
}

ul.list-highlights li::before {
    content: "\2022";
    font-family: Poppins;
    color: var(--li-circle-color);
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}

.what-is {
    background: #F5F4F7;
    border-radius: 30px;
    margin: 70px 0 60px;
}

.what-is .inner-img {
    display: flex;
    justify-content: center;
}
.what-is .inner-img img{
    height: auto;
    max-width: 515px;
    object-fit: cover;
    border-radius: 7%;
}
.what-is .inner {
    text-align: left;
    padding: 50px 50px 50px 30px;
}

#types .types {
    position: relative;
    top: 70px;
    z-index: 1;
}

.types .type {
    background: #FFFFFF;
    box-shadow: 0px 4px 19px rgba(46, 32, 86, 0.1);
    border-radius: 30px;
    padding: 30px;
    margin-top: 40px;
}

.types .type:first-child {
    margin-top: 0px;
}

.types .type .type-title {
    background-size: auto;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;
}
.type-title.marketplace_icon{
    background-image: url(../image/sneakers.png);
}
.type-title.marketplace_icon2{
        background-image: url(../image/shirt.png);
}
.types .type .type-title h3 {
    position: relative;
    left: 40px;
}
#games .types{
    margin-top: 40px;
}
#games .type{
    background: var(--bg-box);
    box-shadow: none;
    padding-left: 60px;
    padding-right: 60px;
}
#games .type h3{
    position: relative;
}
#games .type h3::before{
    color: var(--li-circle-color);
    content: "\2022";
    display: inline-block;
    font-family: Poppins;
    font-size: 26px;
    font-weight: 700;
    margin-left: -20px;
    width: 1em;
}
.grey-box{
    background: var(--bg-box);
    padding: 30px;
    border-radius: 30px;
    margin: 30px 0;
}
.single-row{
    border-radius: 30px;
    padding: 30px;
    margin: 20px 0;
    display: flex;
    align-items: center;
}
.single-row a{
    content: '';
    width: 250px;
    height: 120px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: white;
    float: left;
    margin: 0px 30px 0 0px;
    font-size: 0px;
    box-shadow: 11px 14px 60px rgba(46, 92, 118, 0.2);
    border-radius: 20px;
}

.compatibility-box {
    background:#1E2333;
    padding: 100px 0 50px;
    position: relative;
}
.compatibility-box::after{
    content: '';
    width: 50%;
    height: 400px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(../image/mob_clothes.png);
    position: absolute;
    bottom: -70px;
    right: 0;
}
.compatibility-title {
    margin: 40px 0;
}
.the-race p{
    width: 50%;
}

/* F&Q */
.faq-wrapper {
    margin-top: 60px;
}

.faq:not(:first-of-type) {
    border-top: 1px solid #EDECEE;
}

.faq-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.faq.expanded .faq-title{
    padding-bottom: 20px;
}

.faq-btn {
    background-position: center right;
    background-size: 20px;
    background-repeat: no-repeat;
    height: 20px;
    width: 40px;
    background-color: transparent;
    border: none;
}

.faq-btn:hover {
    cursor: pointer;
}

.faq {
    padding: 10px 30px;
    transition: all 0.1s ease-in;
}
.faq.collapsed .faq-btn {
    background-image: url("../image/faq-expand.svg");
}

.faq.collapsed .faq-body {
    display: none;
}

.faq.expanded .faq-btn {
    background-image: url("../image/faq-collapse.svg");
}

.faq.expanded {
    background: #F5F4F7;
    border-radius: 30px;
    border: none;
}

.faq.faq.expanded+.faq {
    border-top: 0px;
}

.faq.expanded .faq-title h3 {
    margin-bottom: 0px;
}

.faq .faq-title h3 {
    pointer-events: none;
}

.faq-listing {
    margin-top: 30px;
}

.read-more-faq {
    width: 100%;
    border: 1px solid var(--link-primary-color);
    border-radius: 69px;
    height: 59px;
    background: transparent;
    font-weight: bold;
    font-size: 16px;
    font-family: Poppins;
    font-style: normal;
    line-height: 24px;
    color: var(--link-primary-color);
}

.read-more-faq:active {
    background-color: #E2FCFC;
}

.conclution-box {
    background: linear-gradient(94.3deg, #6EACF5 -1.78%, #6EACF5 84.81%);
    border-radius: 30px;
    padding: 30px 100px 50px;
    position: relative;
    top: 70px;
}

/* Footer */
.footer {
    background: #1D1535;
    padding: 100px 0 20px;
    color: #9A91B6;
}

.footer-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.footer .main-logo{
    color: white;
}
@media (min-width: 1400px) {
    .container {
        min-width: 1350px;
    }
    a.button{
        min-width: 170px;
    }
    table.home-table td{
        max-width: 100%;
    }
    .single-row a {
        max-width: 350px;
    }
}
@media only screen and (min-width: 868px) and (max-width: 1400px) {
    .container{
        padding: 0 20px;
    }
}
@media only screen and (min-width: 868px) and (max-width: 1080px) {
    a.secondary{
        font-size: 14px;
    }
    .col2-1, .col1-2, .col2 {
        grid-template-columns: 1fr;
    }
    .main-image{
        display: none;
    }
    .what-is .inner-img {
        justify-content: center;
    }
    .t-logo .logo{
        width: 200px;
    }
    .t-rating{
        justify-content: left
    }
    .types .type .type-title h3{
        max-width: 500px;
    }
}

.m-visible {
    display: none;
}

.m-hidden {
    display: initial;
}

@media only screen and (max-width: 867px) {
    
    .mobile-detector {
        display: none;
    }

    .m-hidden {
        display: none;
    }

    .m-visible {
        display: initial;
    }

    .m-slide-down {
        -moz-transition: height .5s;
        -ms-transition: height .5s;
        -o-transition: height .5s;
        -webkit-transition: height .5s;
        transition: height .5s;
        height: 0;
        overflow: hidden;
    }

    .container {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
    h1{
        font-weight: 600;
        font-size: 32px;
        line-height: 38px;
    }
    h2{
        font-size: 24px;
        line-height: 36px;
    }
    h3{
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
    }
    p{
        font-size: 15px;
    }
    .col2-1, .col1-2, .col2 {
        grid-template-columns: 1fr;
    }
    .sub-title {
        padding: 0px;
    }
    .main-image, .blur-circle-bottom, .blur-circle-right, .overlay{
        display: none;
    }
    .site-header, .intro-primary{
        background: var(--bg-primary-color);
    }
    .intro-primary{
        background-image: url(../image/arrows2.png), url(../image/arrows1.png);
        background-position: left, right;
        background-repeat: no-repeat, no-repeat;
        margin-bottom: 20px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        padding-bottom: 30px;
    }
    .intro-entry{
        margin-bottom: 30px;
    }
    .logo {
        padding: 0px;
    }
    .intro-entry{
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
    }
    .gradient-btn{
        width: auto;
    }
    a.button{
        display: block;
        padding: 20px;
        text-align: center;
        min-width: auto;
        box-shadow: none;
    }
    .inner-img img{
        width: 100%;
    }
    /* Table */
    .t-section{
        padding: 0;
    }
    .m-slider-nav {
        display: flex;
        justify-content: center;
        padding-bottom: 20px;
    }

    .m-slider-nav ul {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-around;
    }

    .m-slider-nav ul li {    
        height: 7px;
        list-style-type: none;
        margin: 20px 0 20px 20px;
        width: 7px;
        background-color: #DEDDE2;
    }

    .m-slider-nav ul li.active {
        background-color: var(--button-primary-color);
    }

    .t-top-tabs {
        overflow: hidden;
        display: block;
    }
    .t-top-title{
        background-image: none;
    }
    
    ul#horizontal-list li {
        transition: margin 0.2s linear;
    }

    ul#horizontal-list{
        min-width: auto;
        display: flex;
        width: 1600px;
    }
    ul.list-highlights{
        padding-left: 0px;
    }
    ul.list-highlights li{
        margin-left: 10px;
    }
    .t-logo {
        background: #f1f4f9;
        border-radius: 25px;
        height: auto;
        padding: 20px 10px;
        width: 100%!important;
    }
    .t-logo .logo {
        background-color: transparent;
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
        float: none;
        font-size: 0;
        height: 30px;
        margin: 0 auto;
        max-width: 100%;
        text-indent: -4000px;
        width: 100px;
    }
    .t-os{
        margin: inherit;
    }
    .t-rating {
        font-size: 14px;
        line-height: 21px;
        color: #2E2056;
    }
    .t-rating{
        justify-content: left
    }
    .home-table thead{
        display: none;
    }
    .home-table tbody tr{
        display: table;
        background: #F9FBFF;
        border-radius: 20px;
        padding: 5px;
        width: 100%;
        max-width: -webkit-fill-available;
    }
    .home-table td {
        border: none!important;
		position: relative;
        text-align: left!important;
        padding: 10px 5px!important;
        display: inline-table;
        max-width: 350px!important;
	}

		.home-table td:before {
			position: absolute;
			top: -6px;
			left: 4px;
			width: 45%;
			padding-right: 10px;
			white-space: nowrap;
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            text-align: left;
            text-transform: uppercase;
            color: #96A0AE;

        }
		.home-table td:nth-of-type(1):before { content: ""; }
		.home-table td:nth-of-type(2):before { content: "Type"; }
		.home-table td:nth-of-type(3):before { content: "Assets Type"; }
		.home-table td:nth-of-type(4):before { content: "Highlights"; }
		.home-table td:nth-of-type(5):before { content: "Rating"; }
        
        .home-table td:nth-of-type(1), .home-table td:nth-of-type(2){width: 48%;line-height: 20px;}
        .home-table td:nth-of-type(3), .home-table td:nth-of-type(5){width: 28%;}
        .home-table td:nth-of-type(4){float: right;width: 62%;}
        .home-table td:nth-of-type(6){width: 100%;display: flow-root;margin: 0 auto;}

        table.home-table td:not(:first-of-type, :last-of-type, :nth-of-type(4))::after{
            background-color: transparent;
        }
    .what-is .inner {
       padding: 0 20px 20px;
    }
    .what-is{
        margin: 40px 0;
    }
    #types .sub-title p {
        margin-bottom: 0px;
    }
    .types .type .type-title h3 {
        left: 0;
        white-space: break-spaces;
        text-indent: 70px;
    }
    .single-row {
        display: grid;
        margin: 30px 0;
        padding: 0px;
    }
    .single-row a{
        margin: 0 0 30px 0;
        width: 100%;
        max-width: 300px;
    }
    .compatibility-box::after{
        content: none;
    }
    .the-race p{
        width: 100%;
    }
    .compatibility-box {
        padding: 50px 0 30px 0;
    }
    .compatibility-box .compatibility-title{
        position: relative;
    }
    .compatibility-box .compatibility-title::after{
        content: '';
        height: 2px;
        width: 80%;
        position: absolute;
        right: 40px;
        background-color: #4E3892;
        bottom: -30px;
        box-shadow: 0px 2px 2px rgb(0 0 0 / 40%);
    }
    #types .types{
        top: 50px;
    }
    #games .type{
        padding-left: 40px;
        padding-right: 30px;
    }
    .faq-wrapper {
        margin-top: 30px;
    }
    .faq {
        padding: 20px 15px;
    }
    .faq.expanded {
        border-radius: 15px;
    }
    .conclution-box{
        padding: 15px;
        top: 50px;
    }
    .footer{
        padding-top: 80px;
    }
    .footer-inner{
        justify-content: center;
        padding-bottom: 30px;
        display: grid;
        text-align: center;
    }
}
@media only screen and (max-width: 320px){
    h2{
        font-size: 17px;
    }
    h3{
        font-size: 16px;
    }
    p{
        font-size: 14px;
    }
    .container{
        padding: 0 10px;
    }
    .gradient-btn, .read-more-faq {
        width: 200px;
    }
    .gradient-btn .inner{
        font-size: 16px;
    }
    .faq{
        padding: 10px;
    }
}