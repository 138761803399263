@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('../font/Raleway-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../font/Raleway-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Raleway';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('../font/Raleway-Italic.ttf') format('truetype');
}

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('../font/Raleway-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('../font/Raleway-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../font/Raleway-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url('../font/Raleway-ExtraBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('../font/Raleway-Black.ttf') format('truetype');
}